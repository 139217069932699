import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, isSupported, onMessage } from 'firebase/messaging';
import firebaseConfig from './firebase-config';
import registerFirebaseServiceWorker, { ServiceWorkerNoSupportError } from 'service-worker-loader?publicPath=/packs/!./firebase-messaging-sw.js';
// import localStorageMemory from 'localstorage-memory';

import notify from './desktop-notification.js';

export var enableFirebaseNotifications = function(onMessageHandler) {
  // if (!isSupported()) { return; }
  if (!document.body.dataset.userId) { return; }
  const firebaseApp = initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);
  if (!!navigator.serviceWorker) {
    let reg = "";
    navigator.serviceWorker.register('/packs/firebase-messaging-sw.js').then(function(registration) {
      reg = registration;
      // messaging.useServiceWorker(registration);
      // messaging.requestPermission().then(function() {
      // console.log('Notification permission granted.');
      // TODO(developer): Retrieve an Instance ID token for use with FCM.
      // ...

      // Get Instance ID token. Initially this makes a network call, once retrieved
      // subsequent calls to getToken will return from cache.
      getToken(messaging, { vapidKey: process.env.FIREBASE_WEB_PUSH_KEY_PAIR, serviceWorkerRegistration: registration }).then(function(currentToken) {
        if (!!currentToken) {
          // console.log('Got Token:', currentToken);
          saveFirebaseToken(currentToken);
        } else {
          console.log('No Instance ID token available. Request permission to generate one.');
        }
      }).catch(function(err) {
        console.log('An error occurred while retrieving token. ', err);
      });

      try {
        // const messaging = getMessaging(firebaseApp);
        onMessage(messaging, (payload) => {
          // console.log('Message received. ', payload);
          // onMessageHandler(payload);
          const title = payload.notification.title;
          const options = {
            body: payload.notification.body
          };
          reg.showNotification(title, options);
        });

        //Callback fired if Instance ID token is updated.
        // messaging.onTokenRefresh(() => messaging.getToken().then(function(refreshedToken) {
        //   console.log('Token refreshed.');
        //   //Indicate that the new Instance ID token has not yet been sent to the
        //   //app server.
        //   saveFirebaseToken(currentToken);
        // }).catch(function(err) {
        //   console.log('Unable to retrieve refreshed token ', err);
        //   showToken('Unable to retrieve refreshed token ', err);
        // }));
      } catch (e) {
        console.log('Unable to setup messaging', e);
      }
    }).catch(function(err) {
      console.log('Unable to get permission to notify.', err);
    });
    // });

    function saveFirebaseToken(currentToken) {

      if (!!currentToken && document.body.dataset.userId) {
        // const storage = ((typeof window !== "undefined") && ("localStorage" in window)) ? window.localStorage : localStorageMemory;
        const storage = window.localStorage;
        // key = "fb-token-#{Rails.user_id}-#{window.location.hostname}"
        const key = `device_token-${ $('body').data('user-id') }-${ window.location.hostname }`;
        const oldToken = storage.getItem(key);
        if (oldToken !== currentToken) {
          $.ajax({
            url: '/devices',
            type: 'POST',
            data: {
              device: { device_token: currentToken, kind: 'web', app_name: 'client' }
            }, success(data) {
              // Pass a key name to get its value.
              storage.setItem(key, currentToken);
            },
            error(result) {
              console.log(result);
            }
          });
        }
      }
    };
  }
};
document.addEventListener('DOMContentLoaded', function() {
  enableFirebaseNotifications(notify);
});
