import 'html5-notification/dist/Notification';

export default { 
  notify: (title, body, options) => {
    if (options == null) { options = {}; }
    if ((window.cordova == null) && !!window.Notification) {
      let notification;
      options.icon = options.notification.icon;
      options.body = body;
      if (Notification.permission === Notification.PERMISSION_GRANTED) {
        notification = new Notification(title, options);
        notification.onclick = function(x) {
          window.focus();
          window.location.href = options.data.url;
        };
          
      } else {
        // require notification permission
        options.icon = options.notification.icon;
        options.body = body;
        Notification.requestPermission().then(function() {
          notification = new Notification(title, options);
          notification.onclick = function(x) {
            window.focus();
            window.location.href = options.data.url;
          };
        });
      }
    }
  }
};
