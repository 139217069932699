function toggleModal(modalID) {
  if (document.getElementById(modalID)) {
    document.getElementById(modalID).classList.toggle("hidden");
    document.getElementById(modalID + "-backdrop").classList.toggle("hidden");
    document.getElementById(modalID).classList.toggle("flex");
    if (document.getElementById("maindashboard")) {
      document.getElementById("maindashboard").classList.toggle("fixed-dashboard");
    }
    if (document.getElementById("authenticationdashboard")) {
      document.getElementById("authenticationdashboard").classList.toggle("fixed-box");
    }
    if (document.getElementById("footer")) {
      document.getElementById("footer").classList.toggle("bottom-100");
    }
    document.getElementById(modalID + "-backdrop").classList.toggle("flex");
    let iframe_tag = document.querySelector('iframe');
    if (iframe_tag) {
      iframeSrc = iframe_tag.src;
      iframe_tag.src = iframeSrc;
    }
  }
};


function _on_boarding_steps_validation_(element) {
  let block_next = true;
  element.find('[required]').each(function() {
    if ($(this).attr('type') == 'checkbox' && !this.checked) {
      block_next = false;
      return false;
    } else if (this.value.length <= 0) {
      block_next = false;
      return false;
    }
  });
  return block_next;
};

document.addEventListener('DOMContentLoaded', () => {

  // $(document).on("click",'.steps-onboarding button[class^="on_boarding_step_to_"]', function(){
  //   if(_on_boarding_steps_validation_($(this).parents('form'))){

  //   }else{
  //   }
  // });

  // $(document).on("keyup","input[data-type='currency']",function(){
  //   formatCurrency($(this));
  // });
  // $(document).on("blur","input[data-type='currency']",function(){
  //   formatCurrency($(this), "blur");
  // });
  // $("input[data-type='currency']").on({
  //   keyup: function() {
  //     formatCurrency($(this));
  //   },
  //   blur: function() { 
  //     formatCurrency($(this), "blur");
  //   }
  // });


  function formatNumber(n) {
    // format number 1000000 to 1,234,567
    return n.replace(/\D/g, "");//.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }


  function formatCurrency(input, blur) {
    // appends $ to value, validates decimal side
    // and puts cursor back in right position.

    // get input value
    var input_val = input.val();

    // don't validate empty input
    if (input_val === "") { return; }

    // original length
    var original_len = input_val.length;

    // initial caret position 
    var caret_pos = input.prop("selectionStart");

    // check for decimal
    if (input_val.indexOf(".") >= 0) {

      // get position of first decimal
      // this prevents multiple decimals from
      // being entered
      var decimal_pos = input_val.indexOf(".");

      // split number by decimal point
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);

      // add commas to left side of number
      left_side = formatNumber(left_side);

      // validate right side
      right_side = formatNumber(right_side);

      // On blur make sure 2 numbers after decimal
      if (blur === "blur") {
        right_side += "00";
      }

      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);

      // join number by .
      input_val = "$" + left_side + "." + right_side;

    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = formatNumber(input_val);
      input_val = "$" + input_val;

      // final formatting
      if (blur === "blur") {
        input_val += ".00";
      }
    }

    // send updated string to input
    input.val(input_val);

    // put caret back in the right position
    var updated_len = input_val.length;
    caret_pos = updated_len - original_len + caret_pos;
    input[0].setSelectionRange(caret_pos, caret_pos);
  }
  $(document).on("click", "a._link_to_remove_association_", function(e) {
    // e.preventDefault();
    // e.stopPropagation();
    // $(this).closest('.nested-fields').find('.discarded_at_hidden_field').val(new Date());
    $(this).closest('.nested-fields').find('.reminder_date_value').removeAttr('min');
    
  });

  $('.generate_code').click(function(e) {
    // e.preventDefault();
    // e.stopPropagation();
    let id = $(this).data('id');
    let phone_number = $(this).data('phone_number');
    let company_id = $(this).data('company_id');
    url = `/cards/generate_code`;
    if (id) {
      url = `/cards/generate_code?id=${ id }`;
    }
    if (phone_number) {
      url = `/cards/generate_code?phone_number=${ phone_number }`;
    }
    if (company_id) {
      url = `/cards/generate_code?company_id=${ company_id }`;
    }
    $.ajax({
      url: url,
      success(data) {
        alert(data.return_message);
      }
    });
  });

  $('.modal-close').click(function(e) {
    // e.preventDefault();
    // e.stopPropagation();
    if ($(this).parents('div.modal').attr('id')) {
      toggleModal($(this).parents('div.modal').attr('id'));
    } else {
      $(this).parents('div.modal').hide();
    }
  });

  modal_id = $('#alert-modal');
  if (modal_id) {
    toggleModal('alert-modal');
  }

  // toggleModal('card-activation-errors')

  modal_id = $('#guest-dashboard-first-visit-modal');
  if (modal_id) {
    toggleModal('guest-dashboard-first-visit-modal');
  }

  modal_id = $('#guest-fund-first-visit-modal');
  if (modal_id) {
    toggleModal('guest-fund-first-visit-modal');
  }

  modal_id = $('#guest-book-travel-first-visit-modal');
  if (modal_id) {
    toggleModal('guest-book-travel-first-visit-modal');
  }

  var url = new URL(window.location.href);
  var c = url.searchParams.get("show");
  if (c == 'intro') {
    // $('a.modal-toggle[data-target="modal-hiw"]').click();
    toggleModal('modal-hiw');
  }

  // var ajax_modal_btn = $('.ajax_modal_btn');
  // ajax_modal_btn.on('click', function(e) {
  //   // e.preventDefault();
  //   // e.stopPropagation();
  //   var url = $(this).data("source");
  //   $.get(url, function(data) {
  //     $(".modal-content").html(data);
  //   });
  // });

  document.querySelectorAll('.ajax_modal_btn').forEach((btn => {
    btn.addEventListener('click', (e) => {
      // e.preventDefault();
      var url = btn.dataset.source;

      $.get(url, function(data) {
        if(document.querySelector(".modal-content") && !data.includes('querySelector')) {
          document.querySelector(".modal-content").innerHTML = data;
        } else {
          // eval(data);
        }
      });
      // var request = new XMLHttpRequest();
      // request.open('GET', url, true);
      // let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
      // request.setRequestHeader('Accept', 'text/javascript');
      // request.setRequestHeader('X-CSRF-Token', token);
      // request.setRequestHeader('X-Requested-With', null);
      // request.onload = function() {
      //   if (this.status >= 200 && this.status < 400) {
      //     // Success!
      //     document.querySelector(".modal-content").innerHTML = this.response;
      //   } else {
      //     // We reached our target server, but it returned an error
      //     alert('error fetching data');
      //   }
      // };
      // request.onerror = function() {
      //   // There was a connection error of some sort
      //   alert('error fetching data');
      // };

      // request.send();
    });
  }));

  document.querySelectorAll('.modal-toggle').forEach((modalToggle) => {
    modalToggle.addEventListener("click", (e) => {
      // e.preventDefault();
      // e.stopPropagation();
      let sidenav_options = ["modal-move-funds", "modal-update-direct-deposit", "modal-reissue-card", "modal-change-pin", "modal-lock-unlock-card", "modal-retrieve-account-information"];
      if (document.getElementById(modalToggle.dataset.target).classList.contains('hidden')) {
        if (sidenav_options.includes(modalToggle.dataset.target)) {
          let modal_toggle_text = $(modalToggle).find('p:last').text();
          if (modal_toggle_text) {
            $(modalToggle).children('p').remove();
            $(modalToggle).append(`<h1 class="text-sm text-primary font-bold">${ modal_toggle_text }</h1>`);
          }
        }
      } else {
        if (sidenav_options.includes(modalToggle.dataset.target)) {
          let sidenav_option = $(`span.modal-toggle[data-target="${ modalToggle.dataset.target }"]`);
          let modal_toggle_text = sidenav_option.find('h1:last').text();
          if (modal_toggle_text) {
            sidenav_option.children('h1').remove();
            sidenav_option.append(`<p class="text-sm body-text">${ modal_toggle_text }</p>`);
          }
        }
      }
      form = modalToggle.closest('form#reseller_update_profile');
      form ||= modalToggle.closest('form#employer_update_company');
      form ||= modalToggle.closest('form#manager_update_profile');
      form ||= modalToggle.closest('form#participant_update_user_profile');
      if (form) {
        required_fields = $(form).find('input:required');
        let field_placeholders = [];
        required_fields.each(function() {
          if (!this.value) {
            field_placeholders.push($(this).attr('placeholder'));
          }
        });
        if (field_placeholders.length > 0) {
          alert(`Please fill required fields: ${ field_placeholders.join(', ') }`);
          return;
        } else {
          if (!modalToggle.classList.contains("generate_code")) {
            let phone_number = modalToggle.dataset.phone_number;
            if (phone_number) {
              url = `/cards/generate_code?phone_number=${ phone_number }`;
            } else {
              url = `/cards/generate_code`;
            }
            $.ajax({
              url: url,
              success(data) {
                alert(data.return_message);
              }
            });
          }
        }
      }
      toggleModal(modalToggle.dataset.target);
    });
  });

  $(document).on("mouseleave", "#myDropdown1", function(e) {
    let myDropdown1 = $(this);
    if (myDropdown1.hasClass('show')) {
      myDropdown1.removeClass('show');
    }
    // console.log(this);
  });

  document.querySelectorAll('.notification_drop_down_button').forEach((noti_drop) => {
    noti_drop.addEventListener("click", (e) => {
      // e.preventDefault();
      // e.stopPropagation();
      let unread_notification_block = $(noti_drop).next();
      if (unread_notification_block.hasClass('show')) {
        unread_notification_block.removeClass('show');
      } else {
        if ($(noti_drop).data('header') == 'participant') {
          unread_notification_ids = [];
          unread_notification_block.find('div.unread_notification').each(function() {
            unread_notification_ids.push($(this).data('id'));
            $(this).removeClass('bg-blue-50');
          });
          let currentdate = new Date();
          var url = `/notifications/bulk_actions?action_type=Read Selected`;
          $.ajax({
            url: url,
            type: "POST",
            data: {
              read_at: currentdate.toISOString(),
              notification_ids: unread_notification_ids
            },
            success(data) {

            }
          });
        }
        unread_notification_block.addClass('show');
      }
    });
  });
});

var myRadios = document.getElementsByName('tabs');
var setCheck;
var x = 0;
for (x = 0; x < myRadios.length; x++) {
  myRadios[x].onclick = function(e) {
    if (setCheck != this) {
      setCheck = this;
    } else {
      this.checked = false;
      setCheck = null;
    }
    // e.stopPropagation();
  };
}
var myRadios = document.getElementsByName('tabr');
var setCheck;
var x = 0;
for (x = 0; x < myRadios.length; x++) {
  myRadios[x].onclick = function(e) {
    if (setCheck != this) {
      setCheck = this;
    } else {
      this.checked = false;
      setCheck = null;
    }
    // e.stopPropagation();
  };
}
var myRadios = document.getElementsByName('tabrs');
var setCheck;
var x = 0;
for (x = 0; x < myRadios.length; x++) {
  myRadios[x].onclick = function(e) {
    if (setCheck != this) {
      setCheck = this;
    } else {
      this.checked = false;
      setCheck = null;
    }
    // e.stopPropagation();
  };
}
var myRadios = document.getElementsByName('taba');
var setCheck;
var x = 0;
for (x = 0; x < myRadios.length; x++) {
  myRadios[x].onclick = function(e) {
    if (setCheck != this) {
      setCheck = this;
    } else {
      this.checked = false;
      setCheck = null;
    }
    // e.stopPropagation();
  };
};
