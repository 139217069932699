import '../stylesheets/frontend';
require.context('../images');
require.context('../../assets/images');
require.context('../videos');
require.context('../files');
import '../shared/cloudinary';
import '../shared/common';
import Inputmask from "inputmask";

function maskPhoneNumberFields(){
  (new Inputmask("(999) 999-9999")).mask(document.querySelectorAll('input.phone_number_mask[type="tel"]'))
}
document.addEventListener('DOMContentLoaded', function() {
  console.log("eqweqwe")
  Inputmask.extendDefaults({
      removeMaskOnSubmit: true
    });
  maskPhoneNumberFields();
  $("button#participant-button").click(function(){
    $(this).parents('form').find('input[type=hidden][name="user[selected_profile]"][id=user_selected_profile]').val('participant');
    $('#emp-select').show();
    $('#emp-unselect').hide();
    $('#employer-select').hide();
    $('#employer-unselect').show();
    $('#resell-select').hide();
    $('#resell-unselect').show();
  });
  $("button#employer-button").click(function(){
    $(this).parents('form').find('input[type=hidden][name="user[selected_profile]"][id=user_selected_profile]').val('employer');
    $('#emp-select').hide();
    $('#emp-unselect').show();
    $('#employer-select').show();
    $('#employer-unselect').hide();
    $('#resell-select').hide();
    $('#resell-unselect').show();
  });
  $("button#resell-button").click(function(){
    $(this).parents('form').find('input[type=hidden][name="user[selected_profile]"][id=user_selected_profile]').val('reseller');
    $('#emp-select').hide();
    $('#emp-unselect').show();
    $('#employer-select').hide();
    $('#employer-unselect').show();
    $('#resell-select').show();
    $('#resell-unselect').hide();
  });
});

// import $ from 'jquery';
//import '../frontend/fbinvite'
import '../shared/firebase-base';
//import 'webpack-jquery-ui/sortable'
// import RailsUjs from 'rails-ujs';
// RailsUjs.start();
//import 'http://connect.facebook.net/en_US/all.js'


